import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../util/cookie/cookies";

export const FinancialInformation = () => {
    const {t} = useTranslation()
    const [state, setState] = useState<boolean>(false)
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>바이넥스의 <span>재무정보를 안내해 드립니다.</span></h3>
                : 
                <h3>Binex provides its <span>financial information</span></h3>

                    
                }
                
                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>setState(false)}>
                            <button className={!state ? 'active' : ''}>{t("invest_financial_tab_1")}</button>
                        </div>
                        <div onClick={()=>setState(true)}>
                            <button className={state ? 'active' : ''}>{t("invest_financial_tab_2")}</button>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={!state ? 'active' : ''}>
                        <h4>
                            {t("invest_financial_content_1_1")}
                            <span>{t("invest_financial_content_1_2")}</span>
                        </h4>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t("invest_financial_content_1_3")}</th>
                                        <th>{t("invest_financial_content_1_28")} (2024.12)</th>
                                        <th>{t("invest_financial_content_1_4")} (2023.12)</th>
                                        <th>{t("invest_financial_content_1_5")} (2022.12)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>{t("invest_financial_content_1_7")}</th>
                                        <td>90,323</td>
                                        <td>70,492</td>
                                        <td>104,869</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_8")}</th>
                                        <td>188,760</td>
                                        <td>185,234</td>
                                        <td>195,787</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_9")}</th>
                                        <td>
                                            <span>279,083</span>
                                        </td>
                                        <td>
                                            <span>255,726</span>
                                        </td>
                                        <td>
                                            <span>300,657</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_10")}</th>
                                        <td>91,994</td>
                                        <td>67,358</td>
                                        <td>98,245</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_11")}</th>
                                        <td>5,538</td>
                                        <td>8,176</td>
                                        <td>7,863</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_12")}</th>
                                        <td>
                                            <span>97,532</span>
                                        </td>
                                        <td>
                                            <span>75,534</span>
                                        </td>
                                        <td>
                                            <span>106,108</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_13")}</th>
                                        <td>16,342</td>
                                        <td>15,881</td>
                                        <td>15,881</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_14")}</th>
                                        <td>163,213</td>
                                        <td>135,817</td>
                                        <td>135,817</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_15")}</th>
                                        <td>-5,235</td>
                                        <td>-5,235</td>
                                        <td>-5,235</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_16")}</th>
                                        <td>-8,567</td>
                                        <td>-6,490</td>
                                        <td>3,329</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_17")}</th>
                                        <td>14,798</td>
                                        <td>40,220</td>
                                        <td>44,757</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_18")}</th>
                                        <td>
                                            <span>181,551</span>
                                        </td>
                                        <td>
                                            <span>180,192</span>
                                        </td>
                                        <td>
                                            <span>194,549</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- 재무상태표 Fin --> */}
                        <h4>
                        {t("invest_financial_content_1_19")}
                            <span>{t("invest_financial_content_1_2")}</span>
                        </h4>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t("invest_financial_content_1_3")}</th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_28")}</span>
                                                <span>2024.01.01 ~ 2024.12.31</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_4")}</span>
                                                <span>2023.01.01 ~ 2023.12.31</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_5")}</span>
                                                <span>2022.01.01 ~ 2022.12.31</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_20")}</th>
                                        <td>130,046</td>
                                        <td>154,825</td>
                                        <td>156,665</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_21")}</th>
                                        <td>-29,571</td>
                                        <td>2,055</td>
                                        <td>18,040</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_22")}</th>
                                        <td><span>-22.74%</span></td>
                                        <td><span>1.33%</span></td>
                                        <td><span>11.52%</span></td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_23")}</th>
                                        <td>-38,249</td>
                                        <td>-4,224</td>
                                        <td>16,025</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_24")}</th>
                                        <td>-34,500</td>
                                        <td>-4,537</td>
                                        <td>13,157</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_25")}</th>
                                        <td>-26.53%</td>
                                        <td>-2.93%</td>
                                        <td>8.40%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- 손익계산서 Fin --> */}
                    </div>
                    {/* <!-- 별도재무정보 Fin --> */}

                    <div className={state ? 'active' : ''}>
                        <h4>
                            {t("invest_financial_content_1_26")}
                            <span>{t("invest_financial_content_1_2")}</span>
                        </h4>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t("invest_financial_content_1_3")}</th>
                                        <th>{t("invest_financial_content_1_28")} (2024.12)</th>
                                        <th>{t("invest_financial_content_1_4")} (2023.12)</th>
                                        <th>{t("invest_financial_content_1_5")} (2022.12)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>{t("invest_financial_content_1_7")}</th>
                                        <td>92,571</td>
                                        <td>73,694</td>
                                        <td>108,952</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_8")}</th>
                                        <td>186,817</td>
                                        <td>183,317</td>
                                        <td>189,410</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_9")}</th>
                                        <td>
                                            <span>279,388</span>
                                        </td>
                                        <td>
                                            <span>257,011</span>
                                        </td>
                                        <td>
                                            <span>298,410</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_10")}</th>
                                        <td>92,219</td>
                                        <td>67,443</td>
                                        <td>98,264</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_11")}</th>
                                        <td>5,562</td>
                                        <td>8,243</td>
                                        <td>7,863</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_12")}</th>
                                        <td>
                                            <span>97,781</span>
                                        </td>
                                        <td>
                                            <span>75,685</span>
                                        </td>
                                        <td>
                                            <span>106,127</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_13")}</th>
                                        <td>16,342</td>
                                        <td>15,881</td>
                                        <td>15,881</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_14")}</th>
                                        <td>163,974</td>
                                        <td>135,602</td>
                                        <td>135,602</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_15")}</th>
                                        <td>-5,612</td>
                                        <td>-5,235</td>
                                        <td>-5,235</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_16")}</th>
                                        <td>-14,049</td>
                                        <td>-11,640</td>
                                        <td>3,329</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_17")}</th>
                                        <td>20,706</td>
                                        <td>46,700</td>
                                        <td>42,029</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_17_1")}</th>
                                        <td>246</td>
                                        <td>18</td>
                                        <td>677</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_18")}</th>
                                        <td>
                                            <span>181,607</span>
                                        </td>
                                        <td>
                                            <span>181,325</span>
                                        </td>
                                        <td>
                                            <span>192,283</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- 연결재무상태표 Fin --> */}
                        <h4>
                            {t("invest_financial_content_1_27")}
                            <span>{t("invest_financial_content_1_2")}</span>
                        </h4>
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>{t("invest_financial_content_1_3")}</th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_28")}</span>
                                                <span>2024.01.01 ~ 2024.12.31</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_4")}</span>
                                                <span>2023.01.01 ~ 2023.12.31</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span>{t("invest_financial_content_1_5")}</span>
                                                <span>2022.01.01 ~ 2022.12.31</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_20")}</th>
                                        <td>130,051</td>
                                        <td>156,677 </td>
                                        <td>134,399</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_21")}</th>
                                        <td>-30,755</td>
                                        <td>17,179</td>
                                        <td>13,109</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_22")}</th>
                                        <td>
                                            <span>-23.65%</span>
                                        </td>
                                        <td>
                                            <span>10.96%</span>
                                        </td>
                                        <td>
                                            <span>9.75%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_23")}</th>
                                        <td>-38,927</td>
                                        <td>15,234</td>
                                        <td>24,234</td>
                                    </tr>
                                    <tr className="gray">
                                        <th>{t("invest_financial_content_1_24")}</th>
                                        <td>-35,180</td>
                                        <td>12,365</td>
                                        <td>19,030</td>
                                    </tr>
                                    <tr>
                                        <th>{t("invest_financial_content_1_25")}</th>
                                        <td>-27.05%</td>
                                        <td>7.89%</td>
                                        <td>14.16%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* <!-- 연결재무정보 Fin --> */}
                </div>
            </div>

        </div>
        </>
    )
}