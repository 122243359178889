import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
export interface Props {onClose : Function;}

export const MainPopup:React.FC<Props> = ({onClose}) => {
    const [cookie, setCookies] = useCookies(['main_popup'])
    const [todayClose, setTodayClose] = useState<boolean>(false)

    // 팝업별 상태 추가
    // const [showPopup2, setShowPopup2] = useState<boolean>(true);
    const [showPopup3, setShowPopup3] = useState<boolean>(true);


    const handleChange = (e:any) => {
        setTodayClose(e.target.checked)
    }
    // const handleClose = () => {
    //     if(todayClose){
    //         const expirationDate = new Date()
    //         expirationDate.setHours(expirationDate.getHours()+24)
    //         setCookies('main_popup', true, {expires : expirationDate})
    //         onClose()
    //     }else{onClose()}
    // }


    // 팝업2 닫기
    // const handleClosePopup2 = () => {
    //     if (todayClose) {
    //     const expirationDate = new Date();
    //     expirationDate.setHours(expirationDate.getHours() + 24);
    //     setCookies("main_popup", true, { expires: expirationDate });
    //     }
    //     setShowPopup2(false); // 팝업2만 닫음
    // };

    // 팝업3 닫기
    const handleClosePopup3 = () => {
        if (todayClose) {
        const expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 24);
        setCookies("main_popup", true, { expires: expirationDate });
        }
        setShowPopup3(false); // 팝업3만 닫음
    };

    const navigate = useNavigate()
    return(
        <>
        {/* {showPopup2 && (
        <div className="mainPopUp2">
            <div>
                <div>
                    <h3>
                        의약품 회수에 관한 공표 <br></br>
                    </h3>
                    <p className="text-01"><span>의약품, 3등급(위해성)</span><br/><br/></p>
                    <p className="text-02">"약사법 제72조의 규정에 의하여 아래의 의약품을 긴급 회수함을 공표합니다."</p>
                </div>
                <div>
                    <div>
                        <p className="image-area"><img src="/assets/images/popup-image-01.png" alt="아스피바장용정(아스피린)"/></p>
                        <p className="text-01">가. 제품명 : 아스피바장용정(아스피린) (100mg, 포장단위 500정)</p>
                        <p>나. 제조일자 또는 유효기간</p>
                        <table>
                            <thead>
                            <tr>
                                <th scope="col">제조번호</th>
                                <th scope="col">제조일자</th>
                                <th scope="col">유효기한</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>22001</td>
                                <td>2022.01.26</td>
                                <td>2025.01.25</td>
                            </tr>
                            <tr>
                                <td>22002</td>
                                <td>2022.04.12</td>
                                <td>2025.04.11</td>
                            </tr>
                            <tr>
                                <td>22003</td>
                                <td>2022.04.19</td>
                                <td>2025.04.18</td>
                            </tr>
                            <tr>
                                <td>22004</td>
                                <td>2022.08.12</td>
                                <td>2025.08.11</td>
                            </tr>
                            <tr>
                                <td>22005</td>
                                <td>2022.10.11</td>
                                <td>2025.10.10</td>
                            </tr>
                            <tr>
                                <td>23001</td>
                                <td>2023.07.05</td>
                                <td>2026.07.04</td>
                            </tr>
                            <tr>
                                <td>23002</td>
                                <td>2023.09.20</td>
                                <td>2026.09.19</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>다. 제조번호 : 22001, 22002, 22003, 22004, 22005, 23001, 23002</p>
                    </div>
                    <div>
                        <p>라. 회수사유 : 성상 부적합 우려(낱알 표면 매끄럽지 못함, 낱알끼리<br/>
                            붙어 있는 현상)로 영업자 회수 ※ 포장단위 500정에 한함</p>
                        <p>마. 회수방법(소비자 반품 등 절차) : 취급 판매업소 방문, 우편 수거 </p>
                        <p>바. 소비자 유의사항
                            <ul className="popup-list">
                                <li>해당 제품의 제조번호로 투여 받은 환자는 구매처에 반품하고
                                    담당 의사와 상담 하시기 바랍니다.
                                </li>
                                <li>회수 대상 의약품으로 인해 발생하는 부작용은 한국의약품안전
                                    관리원에 보고해 주시기 바랍니다.</li>
                            </ul>
                        </p>
                        <p>사. 회수의무자 : (주)바이넥스 (대표자 이혁종)</p>
                        <p>아. 회수의무자 소재지 : 부산광역시 사하구 다대로 368-3</p>
                        <p>자. 연락처 : 전화 051-790-4449, 팩스 051-790-4400</p>
                        <p>차. 자료작성연월일 2024년 11월 18일</p>
                        <div className="last-popup-div">
                            <p><img src="/assets/images/popUp_logo.png" alt="BINEX"/></p>
                            <div>
                                당해 의약품을 보관하고 있는 의약품의 판매업자 및 약국, 의료기관<br/>
                                등에서는 즉시 판매를 중지하고 회수의무자에게 반품하여 주시기 바랍니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btnBox">
                <div>
                    <input type="checkbox" name="" onChange={handleChange} checked={todayClose} id="today_close"/>
                    <label htmlFor="today_close">오늘 하루 열지 않음</label>
                </div>
                <button onClick={handleClosePopup2}>닫기</button>
            </div>
        </div>
        )} */}

        {showPopup3 && (
        <div className="mainPopUp3">
            <div>
                <div>
                    <h3>
                        바이넥스, 미국 FDA cGMP 승인 획득 <br></br>
                    </h3>
                    <p className="text-01">
                    <a href="https://www.bi-nex.com/binex/promotion-center/52" target="_blank" rel="noopener noreferrer">
                        <span>관련 기사 바로가기</span>
                    </a>
                    <br/><br/>
                    </p>
                </div>
                <div>
                    <ul>
                        <p className="image-area"><img src="/assets/images/abundantTrackRecord_logo6.png" alt="FDA"/></p>
                        <table>.</table>
                        <p>바이넥스가 미국 FDA의 cGMP(우수 의약품 제조 및 품질 관리 기준) 실사를 성공적으로 통과하였습니다.</p><br/>
                        <p>이번 승인을 통해 바이넥스는 미국, 유럽, 일본을 비롯한 글로벌 시장을 대상으로 cGMP 수준의 바이오의약품 생산 및 공급이 가능한 경쟁력 있는 CDMO로 한 단계 도약하였습니다.</p><br/>
                        <p>앞으로도 믿을 수 있는 CDMO 파트너로서, 함께 성장하며 최고의 품질과 서비스로 보답하겠습니다.</p>
                        <p className="image-area"><img src="/assets/images/popUp_logo.png" alt="BINEX"/></p>
                    </ul>
                </div>
            </div>
            <div className="btnBox">
                <div>
                    <input type="checkbox" name="" onChange={handleChange} checked={todayClose} id="today_close"/>
                    <label htmlFor="today_close">오늘 하루 열지 않음</label>
                </div>
                <button onClick={handleClosePopup3}>닫기</button>
            </div>
        </div>)}
        </>
    )
}
