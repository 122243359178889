import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../../util/cookie/cookies";

export const BussinessOverView = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()
    const [state, setState] = useState<boolean>(false)
    const handleTransPage = (url : string) => {
        navigate(url)
    }
    const handleRFPButtonClick = () => {
        // 파일을 다운로드할 때 사용할 URL
        const fileUrl = '/Questionnaire_for_BINEX_Proposal_ver.5.docx';  // 파일 경로에 맞게 수정

        // 가상의 <a> 엘리먼트를 생성하고 클릭하여 파일을 다운로드
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Questionnaire_for_BINEX_Proposal_ver.docx';  // 다운로드되는 파일 이름
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>Your True and Reliable <span>CDMO Partner, BINEX</span></h3>
                {/* <p><strong>{t("cdmo_businessoverview_title")}</strong></p> */}
                <p>{t("cdmo_businessoverview_content_1")}</p>
                <p>{t("cdmo_businessoverview_content_2")}</p>

                <div>
                    <h4>Core Competency</h4>
                    <ul>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundant_track_record.png" alt="Abundant Track Record"/>
                            </div>
                            <span>
                                Abundant<br/>
                                Track Record
                            </span>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/flexible_manufacturing_facility.png" alt="Flexible Manufacturing Facility"/>
                            </div>
                            <span>
                                Flexible<br/>
                                Manufacturing Facility
                            </span>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/client_oriented_CDMO.png" alt="Client Oriented CDMO"/>
                            </div>
                            <span>
                                Client<br/>
                                Oriented CDMO
                            </span>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/oneStop_CDMO_service.png" alt="One-stop CDMO Service"/>
                            </div>
                            <span>
                                One-stop<br/>
                                CDMO Service
                            </span>
                        </li>
                    </ul>
                </div>
                {/* <!-- Core Competency Fin --> */}

                <div>
                    <h4>Abundant Track Record</h4>
                    <p>
                        {t("cdmo_businessoverview_sub_title_1")}
                        <span>{t("cdmo_businessoverview_sub_title_2")}</span>
                    </p>


                    {/* <!-- custom을 공통으로 사용하기 위해 tabBtnBox 클래스 사용, 버튼 기능 없음 --> */}
                    <div className="tabBtnWrap">
                        <div className="tabBtnBox">
                            <div>
                                <div>
                                    <span>Clients</span>
                                </div>
                                <div>
                                    <span>GMP Batches</span>
                                </div>
                                <div>
                                    <span>Inspection & Audits</span>
                                </div>
                                <div>
                                    <span>IND/BLA Approval</span>
                                </div>
                            </div>
                        </div>
                        <div className="tabBtnBox">
                            <div>
                                <div>
                                    <span>140+</span>
                                </div>
                                <div>
                                    <span>1200+</span>
                                </div>
                                <div>
                                    <span>40+</span>
                                </div>
                                <div>
                                    <span>95+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- custom을 공통으로 사용하기 위해 tabBtnBox 클래스 사용, 버튼 기능 없음 --> */}

                    <ul className="mobileBox">
                        <li>
                            <span>Clients</span>
                            <span>140+</span>
                        </li>
                        <li>
                            <span>GMP Batches</span>
                            <span>1200+</span>
                        </li>
                        <li>
                            <span>Inspection & Audits</span>
                            <span>40+</span>
                        </li>
                        <li>
                            <span>IND/BLA Approval</span>
                            <span>95+</span>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo6.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo4.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo7.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo2.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo3.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src="/assets/images/abundantTrackRecord_logo5.png" alt="Abundant Track Record logo"/>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <!-- Abundant Track Record Fin --> */}

                <div>
                    <h4>Flexible Manufacturing Facility</h4>
                    <p>
                        {t("cdmo_businessoverview_sub_title_2_1")}
                        <span>{t("cdmo_businessoverview_sub_title_2_2")}</span>
                    </p>

                    <div className="tabBtnWrap">
                        <div className={!state ? 'imgBox active' : 'imgBox'} onClick={()=>setState(false)} style={{cursor : 'pointer'}}>
                            <img src="/assets/images/flexible_manufacturing_facility_songdo.png" alt="songdo"/>
                        </div>
                        <div className={state ? 'imgBox active' : 'imgBox'} onClick={()=>setState(true)} style={{cursor : 'pointer'}}>
                            <img src="/assets/images/flexible_manufacturing_facility_osong.png" alt="osong"/>
                        </div>
                    </div>

                    <div className="tabContentBox">
                        <div className={!state ? 'active' : ''}>
                            <span>Drug Substance</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plant</th>
                                        <th>Classification</th>
                                        <th>Suite</th>
                                        <th>Scale (type) *</th>
                                        <th>Train (W.V.) *</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th rowSpan={7}>Songdo</th>
                                        <td rowSpan={5} className="bold">Mammalian</td>
                                        <td rowSpan={2}>Suite 1</td>
                                        <td>200 L (SUB)</td>
                                        <td>200 L </td>
                                    </tr>
                                    <tr>
                                        <td>1,000 L (SUB)</td>
                                        <td>200 L  - 1,000 L </td>
                                    </tr>
                                    <tr>
                                        <td>Suite 2</td>
                                        <td>500 L (STS)</td>
                                        <td>500 L</td>
                                    </tr>
                                    <tr>
                                        <td>Suite 3</td>
                                        <td>1,000 L (STS)</td>
                                        <td>200 L - 1,000 L</td>
                                    </tr>
                                    <tr>
                                        <td>Suite 4</td>
                                        <td>1,000 L (STS)</td>
                                        <td>200 L - 1,000 L</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2} className="bold">Microbial</td>
                                        <td>Suite 5</td>
                                        <td>180 L (STS)</td>
                                        <td>180 L</td>
                                    </tr>
                                    <tr>
                                        <td>Suite 6</td>
                                        <td>500 L (STS)</td>
                                        <td>500 L</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <span>* STS: Stainless Steel Bioreactor/ SUB: Single Use Bioreactor</span>
                                <span>** W.V. : Working Volume</span>
                            </div>

                            <span>Drug Product</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plant</th>
                                        <th>Type</th>
                                        <th>Available Size</th>
                                        <th>Capacity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th rowSpan={3}>Songdo</th>
                                        <td>Liquid Vial</td>
                                        <td>2 – 50 mL</td>
                                        <td>10,000 vials/batch</td>
                                    </tr>
                                    <tr>
                                        <td>Lyophilized Vial</td>
                                        <td>2 – 50 mL</td>
                                        <td>5,000 vials/batch</td>
                                    </tr>
                                    <tr>
                                        <td>Pre-Filled Syringe</td>
                                        <td>1 – 2.25 mL</td>
                                        <td>6,000 vials/batch</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* <!-- songdo --> */}

                        <div className={state ? 'active' : ''}>
                            <span>Drug Substance</span>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plant</th>
                                        <th>Classification</th>
                                        <th>Suite</th>
                                        <th>Scale (type) *</th>
                                        <th>Train (W.V.) *</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th rowSpan={3}>Osong</th>
                                        <td rowSpan={3} className="bold">Mammalian</td>
                                        <td rowSpan={2}>Suite 7</td>
                                        <td>1,000 L (STS)</td>
                                        <td>1,000 L</td>
                                    </tr>
                                    <tr>
                                        <td>1,000 L (STS)</td>
                                        <td>200 L - 1,000 L</td>
                                    </tr>
                                    <tr>
                                        <td>Suite 8</td>
                                        <td>5,000 L (STS)</td>
                                        <td>1,000 L - 5,000 L</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div>
                                <span>* STS: Stainless Steel Bioreactor/ SUB: Single Use Bioreactor</span>
                                <span>** W.V. : Working Volume</span>
                            </div>
                        </div>
                        {/* <!-- osong --> */}
                    </div>
                </div>
                {/* <!-- Flexible Manufacturing Facility Fin --> */}

                <div>
                    <h4>Client Oriented CDMO</h4>
                    <p>
                        {t("cdmo_businessoverview_sub_title_3_1")}
                    </p>
                    <p>
                        {t("cdmo_businessoverview_sub_title_3_2")}
                    </p>
                    {/* <ul className="available_modality_list">
                        <li>
                            <span>
                                Monoclonal<br/>
                                Antibody
                            </span>
                        </li>
                        <li>
                            <span>
                                Bispecific<br/>
                                Antibody
                            </span>
                        </li>
                        <li>
                            <span>
                                Fc-fusion<br/>
                                Protein
                            </span>
                        </li>
                        <li>
                            <span>
                                Recombinant<br/>
                                Protein
                            </span>
                        </li>
                        <li>
                            <span>
                                pDNA<br/>
                                (gene therapy)
                            </span>
                        </li>
                        <li>
                            <span>
                                Recombinant<br/>
                                Vaccine<br/>
                                (DNA vaccine)
                            </span>
                        </li>
                    </ul> */}

                    <div className="imgBox center">
                        <span>Project Management</span>
                        <img src="/assets/images/steeringCommittee.png" alt="Steering Committee"/>
                    </div>

                    <div className="imgBox center">
                        <span>Regulatory Support</span>
                        {lang === 'Kr' ?
                        <img src="/assets/images/regulatory_support.png" alt="Regulatory Support"/>
                        :
                        <img src="/assets/images/regulatory_support_en.png" alt="Regulatory Support"/>
                        }
                    </div>
                </div>
                {/* <!-- Client Oriented CDMO Fin --> */}

                <div>
                    <h4>One Stop CDMO Service</h4>
                    <p>{t("cdmo_businessoverview_sub_title_4")}</p>

                    <ul>
                        <li onClick={()=>handleTransPage('/bioMedicine/cdmoservice?tab=1')}>
                            <div className="imgBox">
                                <span>{t("cdmo_businessoverview_plus")}</span>
                                <img src="/assets/images/OneStop_CDMOService1.png" alt="One Stop CDMO Service"/>
                            </div>
                            <dl>
                                <dt>{t("cdmo_businessoverview_tab_1")}</dt>
                                <dd>{t("cdmo_businessoverview_tab_1_1")}</dd>
                                <dd>{t("cdmo_businessoverview_tab_1_2")}</dd>
                            </dl>
                        </li>
                        <li onClick={()=>handleTransPage('/bioMedicine/cdmoservice?tab=2')}>
                            <div className="imgBox">
                                <span>{t("cdmo_businessoverview_plus")}</span>
                                <img src="/assets/images/OneStop_CDMOService2.png" alt="One Stop CDMO Service"/>
                            </div>
                            <dl>
                                <dt>{t("cdmo_businessoverview_tab_2")}</dt>
                                <dd>{t("cdmo_businessoverview_tab_2_1")}</dd>
                                <dd>{t("cdmo_businessoverview_tab_2_2")}</dd>
                            </dl>
                        </li>
                        <li onClick={()=>handleTransPage('/bioMedicine/cdmoproduction?tab=1')}>
                            <div className="imgBox">
                                <span>{t("cdmo_businessoverview_plus")}</span>
                                <img src="/assets/images/OneStop_CDMOService3.png" alt="One Stop CDMO Service"/>
                            </div>
                            <dl>
                                <dt>{t("cdmo_businessoverview_tab_3")}</dt>
                                <dd>{t("cdmo_businessoverview_tab_3_1")}</dd>
                                <dd>{t("cdmo_businessoverview_tab_3_2")}</dd>
                            </dl>
                        </li>
                        <li onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>
                            <div className="imgBox">
                                <span>{t("cdmo_businessoverview_plus")}</span>
                                <img src="/assets/images/OneStop_CDMOService4.png" alt="One Stop CDMO Service"/>
                            </div>
                            <dl>
                                <dt>{t("cdmo_businessoverview_tab_4")}</dt>
                                <dd>{t("cdmo_businessoverview_tab_4_1")}</dd>
                                <dd>{t("cdmo_businessoverview_tab_4_2")}</dd>
                            </dl>
                        </li>
                    </ul>

                    <div className="btnBox">
                        <button className="blackBtn" onClick={()=>handleTransPage('/bioMedicine/cdmoservice?tab=1')}>CDO Service</button>
                        <button className="redBtn" onClick={()=>handleTransPage('/bioMedicine/cdmoproduction?tab=1')}>CMO Service</button>
                    </div>
                </div>
                {/* <!-- One Stop CDMO Service Fin --> */}

                <div>
                    <h4>{t("cdmo_businessoverview_tab_5")}</h4>

                    {/* <!-- custom을 공통으로 사용하기 위해 tabBtnBox 클래스 사용, 버튼 기능 없음 --> */}
                    <div className="listBox">
                        <div>
                            <div>
                                <span className="step">STEP 01</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_1")}</span>
                                <span className="arrow"></span>
                            </div>
                            <div>
                                <span className="step">STEP 02</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_2")}</span>
                                <span className="arrow"></span>
                            </div>
                            <div>
                                <span className="step">STEP 03</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_3")}</span>
                                <span className="arrow"></span>
                            </div>
                            <div>
                                <span className="step">STEP 04</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_4")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="listBox">
                        <div>
                            <div>
                                <span className="arrow"></span>
                                <span className="step">STEP 05</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_5")}</span>
                            </div>
                            <div>
                                <span className="arrow"></span>
                                <span className="step">STEP 06</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_6")}</span>
                            </div>
                            <div>
                                <span className="arrow"></span>
                                <span className="step">STEP 07</span>
                                <span className="txt">{t("cdmo_businessoverview_tab_5_7")}</span>
                            </div>
                        </div>
                    </div>
                    {/* <!-- custom을 공통으로 사용하기 위해 tabBtnBox 클래스 사용, 버튼 기능 없음 --> */}

                    <div className="btnBox">
                        {/*<button className="blackBtn" onClick={handleRFPButtonClick}>Request for Proposal(RFP)</button>*/}
                        <button className="redBtn" onClick={()=>handleTransPage('/contactUs')}>Service Inquiry</button>
                    </div>
                </div>
                {/* <!-- 수탁 및 수주절차 Fin --> */}
            </div>
        </div>
        </>
    )
}
